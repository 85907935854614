/* TRANSITION STYLES */
.toast-enter {
    transform: scale(0);
    opacity: 0;
    transition: transform 150ms ease-in, opacity 150ms;
}

.toast-enter-active {
    transform: scale(1);
    opacity: 1;
    transition: transform 150ms ease-out, opacity 150ms;
}

.toast-exit {
    transform: scale(1);
    opacity: 1;
}

.toast-exit-active {
    transform: scale(0);
    opacity: 0;
    transition: transform 150ms ease-in, opacity 150ms;
}
