/* DEFAULT STYLES */
.modal-screen-wrapper {
    opacity: 0;
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    will-change: transform, opacity;
    transition: opacity 200ms ease-out;
}

/* TRANSITION STYLES */
.modal-enter-active, .modal-enter-done {
    opacity: 1;
}

.modal-enter-active .modal-content, .modal-enter-done .modal-content {
    transform: translateY(0);
}

.modal-enter-active, .modal-enter-done, .modal-exit, .modal-exit-active {
    z-index: 4;
}
