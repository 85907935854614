* {
  outline: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, button, textarea {
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration-line: none;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

button {
  padding: 0;
  margin: 0;
  display: block;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

button:disabled, button[disabled] {
  opacity: 0.75;
  cursor: default;
}

#main {
  position: relative;
}

.scrollable {
  overflow: overlay;
  scrollbar-color: rgba(220, 220, 220, 0.8) rgba(255, 255, 255, 0.2);
}

.scrollable::-webkit-scrollbar {
  width: 4px;
}

.scrollable::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.2);
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: rgba(220, 220, 220, 0.8);
  border-radius: 4px;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #808080	;
}

.disable-scroll {
  overflow: hidden;
  position: relative;
}

@media (min-width: 768px) {
  .scrollable::-webkit-scrollbar {
    width: 7px;
  }
}
