.dropdown-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 3;
}

/* TRANSITION STYLES */
.dropdown-enter {
    opacity: 0;
    transition: opacity 150ms ease-in;
}

.dropdown-enter-active {
    opacity: 1;
    transition: opacity 150ms ease-out;
}

.dropdown-exit {
    opacity: 1;
}

.dropdown-exit-active {
    opacity: 0;
}
